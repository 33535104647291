import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { accessTokenAtom, homeAtom, orgAtom } from '../atoms';
import { REACT_APP_API_DOMAIN, REACT_APP_RECOMMENDERDOMAIN, REACT_APP_RECOMMENDER_API_KEY } from '../env';
import { Listener } from '../types';
import { useEffect, useState } from 'react';
import { Home } from '../types';

export default function DeleteListener() {
  const navigate = useNavigate();
  const [accessToken] = useAtom(accessTokenAtom);
  const [home, setHome] = useAtom(homeAtom);
  const [org] = useAtom(orgAtom);
  const queryClient = useQueryClient();

  const getHomeListeners = async () => {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);
    const resp = await fetch(REACT_APP_API_DOMAIN + `/pro/home/listeners?home_id=${home?.id}`, {
      headers,
    });
    return (await resp.json()) as Listener[];
  };

  const deleteListener = async (listener: Listener) => {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);
    const resp = await fetch(REACT_APP_API_DOMAIN + `/pro/listener/${listener.id}`, {
      method: 'DELETE',
      headers,
    });
    console.log('resp delete Listener profile', JSON.stringify(resp));

    const headers_taste_profile = new Headers();
    headers_taste_profile.append('Authorization', 'Bearer ' + REACT_APP_RECOMMENDER_API_KEY);
    const resDeleteTasteProfile = await fetch(
      REACT_APP_RECOMMENDERDOMAIN + `/tasteprofile/${listener.taste_profile_id}`,
      {
        method: 'DELETE',
        headers: headers_taste_profile,
      }
    );

    console.log('resp delete Listener tasteprofile', JSON.stringify(resDeleteTasteProfile));

    alert(`Listener ${listener.name}  deleted`);
    queryClient.invalidateQueries(['getVeraUsers']);
  };

  const {
    data: listeners,
    isLoading,
    isError,
  } = useQuery({ queryKey: ['getHomeListeners'], queryFn: getHomeListeners });

  const putListener = async (listener: Listener) => {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);
    headers.append('Content-Type', 'application/json');
    const resp = await fetch(REACT_APP_API_DOMAIN + `/pro/listener/${listener.id}`, {
      method: 'PUT',
      body: JSON.stringify(listener),
      headers,
    });
    console.log('resp put Listener', JSON.stringify(resp));
    queryClient.invalidateQueries(['getVeraUsers']);
    return await resp.json();
  };

  const getHomes = async () => {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);
    const resp = await fetch(REACT_APP_API_DOMAIN + '/admin/organisation/' + org?.id + '/homes', {
      headers,
    });
    return (await resp.json()) as Home[];
  };
  const {
    data: homes,
    isLoading: isHomeLoading,
    isError: isHomeError,
  } = useQuery({ queryKey: ['getHomes'], queryFn: getHomes });

  const [selectValue, setSelectValue] = useState(
    listeners?.map((e) => {
      return e.home_id;
    })
  );

  useEffect(() => {
    if (listeners) {
      setSelectValue(
        listeners?.map((e) => {
          return e.home_id;
        })
      );
    }
  }, [isLoading, listeners]);

  if (isLoading || isHomeLoading) {
    return <div>Loading</div>;
  }
  if (isError || isHomeError) {
    return <div>Something went wrong. Please refresh</div>;
  }

  return (
    <>
      <div>Listeners for: {home?.name}</div>
      <div style={rowStyle}>
        <div style={{ width: '15%' }}>ID</div>
        <div style={{ width: '20%' }}>Name</div>
        <div style={{ width: '15%' }}>Archived</div>
        <div style={{ width: '25%' }}>Taste Profile ID</div>
        <div style={{ width: '20%' }}>Delete</div>
        <div style={{ width: '10%' }}>Edit</div>
      </div>
      {listeners.length ? (
        listeners.map((c, index) => (
          <div key={c.id} style={{ ...rowStyle }}>
            <div style={{ width: '15%' }}>{c.id}</div>
            <div style={{ width: '20%', wordWrap: 'break-word', whiteSpace: 'normal' }}>{c.name}</div>
            <div style={{ width: '15%', wordWrap: 'break-word', whiteSpace: 'normal' }}>
              {c.archived ? 'True' : 'False'}
            </div>
            <div style={{ width: '25%', wordWrap: 'break-word', whiteSpace: 'normal' }}>{c.taste_profile_id}</div>
            <div style={{ width: '20%', marginLeft: '10px' }}>
              <button
                onClick={() => {
                  deleteListener(c);
                }}
              >
                Delete
              </button>
            </div>
            <div style={{ width: '10%', marginLeft: '10px' }}>
              <select
                name="Edit Listener Home"
                value={selectValue ? selectValue[index] : 0}
                onChange={async (e) => {
                  const newListener = c;
                  newListener.home_id = parseInt(e.target.value);
                  const res = await putListener(newListener);
                  console.log(res);
                  if (!selectValue) {
                    return;
                  }
                  const result = [...selectValue];
                  result[index] = parseInt(res.home_id);
                  setSelectValue(result);
                }}
              >
                {homes?.map((home, i) => {
                  return (
                    <option key={i} value={home.id}>
                      {home.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        ))
      ) : (
        <div>No Vera Users</div>
      )}
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </button>
    </>
  );
}

const rowStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '10px',
  padding: '10px',
  alignItems: 'center',
  borderBottom: '1px solid #000', // Add this line
  width: '80%', // Add this line to make the border stretch across the screen
};

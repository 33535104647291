import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accessTokenAtom, homeAtom, orgAtom } from '../atoms';
import Checkbox from '../components/Checkbox';
import TextInput from '../components/TextInput';
import { REACT_APP_API_DOMAIN } from '../env';
export default function Home() {
  const navigate = useNavigate();
  const [accessToken] = useAtom(accessTokenAtom);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [maxCaregivers, setMaxCaregivers] = useState('');
  const [maxListeners, setMaxListeners] = useState('');
  const [isLive, setIsLive] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const queryClient = useQueryClient();

  const [org, setOrg] = useAtom(orgAtom);
  const [home, setHome] = useAtom(homeAtom);
  useEffect(() => {
    if (home) {
      setName(home.name);
      setAddress(home.address);
      setPostcode(home.postcode);
      setCountry(home.country);
      setCity(home.city);
      setState(home.state);
      setMaxCaregivers(home.max_caregivers);
      setMaxListeners(home.max_listeners);
      setIsLive(home.is_live);
    }
  }, [home]);

  useEffect(() => {
    if (!accessToken || !org) {
      navigate('/');
    }
  }),
    [accessToken, org];
  const handleSubmit = async () => {
    if (!Number.isInteger(parseInt(maxCaregivers))) {
      alert('Max Caregivers must be a number');
      return;
    }

    if (!Number.isInteger(parseInt(maxListeners))) {
      alert('Max Listeners must be a number');
      return;
    }
    console.log(parseInt(maxCaregivers));
    const newHome = {
      name,
      organisation_id: org?.id,
      address,
      postcode,
      country,
      city,
      state,
      max_caregivers: parseInt(maxCaregivers),
      max_listeners: parseInt(maxListeners),
    };
    let url = REACT_APP_API_DOMAIN + '/admin/home';
    const headers = new Headers();
    headers.append('Authorization', accessToken);
    headers.append('Content-Type', 'application/json');
    const options = { method: 'POST', body: JSON.stringify(newHome), headers };
    if (home) {
      url += '/' + home.id;
      options.method = 'PUT';
    }
    await fetch(url, options);
    queryClient.invalidateQueries();
    setHome(undefined);
    navigate('/edithome');
  };

  const handleDelete = async () => {
    if (!home) {
      alert('Something went wrong');
      return;
    }
    if (!canDelete) {
      setCanDelete(true);
      alert(
        'Warning: This will delete the home and all caregivers and listeners associated with it. Please select delete again if you are sure you would like to do this'
      );
      return;
    }
    const url = REACT_APP_API_DOMAIN + '/admin/home/' + home?.id;
    const headers = new Headers();
    headers.append('Authorization', accessToken);
    headers.append('Content-Type', 'application/json');
    const options = { method: 'DELETE', headers };
    const response = await fetch(url, options);
    queryClient.invalidateQueries();
    setHome(undefined);
    navigate('/edithome');
  };
  return (
    <>
      <div>Home Information</div>
      <div>Org: {org?.name}</div>
      {home ? <div>Home ID: {home.id}</div> : null}
      <TextInput title="name" value={name} setValue={setName} />
      <TextInput title="address" value={address} setValue={setAddress} />
      <TextInput title="postcode" value={postcode} setValue={setPostcode} />
      <TextInput title="country" value={country} setValue={setCountry} />
      <TextInput title="city" value={city} setValue={setCity} />
      <TextInput title="state" value={state} setValue={setState} />
      <TextInput title="max caregivers" value={maxCaregivers} setValue={setMaxCaregivers} />
      <TextInput title="max listeners" value={maxListeners} setValue={setMaxListeners} />
      <Checkbox title="is live" value={isLive} setValue={setIsLive} />
      <button onClick={() => handleSubmit()}>{home ? 'save' : 'add'}</button>
      {home ? <button onClick={() => handleDelete()}>delete</button> : null}
      <button
        onClick={() => {
          setHome(undefined);
          navigate(-1);
        }}
      >
        Back
      </button>
    </>
  );
}

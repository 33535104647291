import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accessTokenAtom, caregiverAtom, homeAtom, orgAtom } from '../atoms';
import Checkbox from '../components/Checkbox';
import TextInput from '../components/TextInput';
import { REACT_APP_API_DOMAIN } from '../env';
export default function CaregiverForm() {
  const navigate = useNavigate();
  const [org, setOrg] = useAtom(orgAtom);
  const [home, setHome] = useAtom(homeAtom);
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [profession, setProfession] = useState('');
  const [role, setRole] = useState('caregiver');
  const [homeId, setHomeId] = useState(home?.id || '');
  const [isLive, setIsLive] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [generatePassword, setGeneratePassword] = useState(true);
  const [newPassword, setNewPassword] = useState(false);
  const [autoVerification, setAutoVerification] = useState(false);

  const [caregiver, setCaregiver] = useAtom(caregiverAtom);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (caregiver) {
      setName(caregiver.name);
      setEmail(caregiver.email);
      setPhone(caregiver.mobile);
      setCity(caregiver.city);
      setState(caregiver.state);
      setCountry(caregiver.country);
      setProfession(caregiver.profession);
      setIsLive(caregiver.is_live);
      setRole(caregiver.role ?? 'caregiver');
      setHomeId(caregiver.home_id);
      setGeneratePassword(false);
    } else {
      setHomeId(home?.id || '');
    }
  }, [caregiver]);

  useEffect(() => {
    if (!accessToken || !home || !org) {
      navigate('/');
    }
  }),
    [accessToken, org, home];
  const handleSubmit = async () => {
    const newOrg = {
      name,
      home_id: Number(homeId),
      email,
      mobile: phone,
      profession,
      role,
      country,
      city,
      state,
      isLive,
      password: (!caregiver && generatePassword) || (caregiver && !newPassword) ? null : password,
    };

    // change the password
    if (newPassword) {
      console.log('Reached the password change code');

      if (password.length < 8) {
        alert('Password must be at least 8 characters');
      } else {
        const url = REACT_APP_API_DOMAIN + '/admin/change-password';
        const headers = new Headers();
        headers.append('Authorization', accessToken);
        headers.append('Content-Type', 'application/json');

        const options = { method: 'POST', body: JSON.stringify({ username: email, password: password }), headers };
        const response = await fetch(url, options);
        if (response.status === 200) {
          alert('password change successful');
        } else {
          alert('password change failed');
        }

        console.log('update password', response);
      }
    }

    let url = REACT_APP_API_DOMAIN + '/admin/caregiver';
    if (autoVerification) {
      url += '/custom';
    }
    console.log('caregiver details:', newOrg);
    const headers = new Headers();
    headers.append('Authorization', accessToken);
    headers.append('Content-Type', 'application/json');
    const options = { method: 'POST', body: JSON.stringify(newOrg), headers };
    if (caregiver) {
      url += '/' + caregiver?.id;
      options.method = 'PUT';
    }
    const response = await fetch(url, options);
    const data = await response.json();
    console.log('res data', data);
    if (data.password) {
      alert('password (you will only see this once):' + data.password);
    }
    if (data.error) {
      alert(data.error);
    }
    queryClient.invalidateQueries();
    setCaregiver(undefined);
    navigate('/editcaregiver');
  };

  const handleDelete = async () => {
    if (!caregiver) {
      alert('Something went wrong');
      return;
    }
    if (!canDelete) {
      setCanDelete(true);
      alert(
        'Warning: This will delete the caregiver and their account credentials. Please select delete again if you are sure you would like to do this'
      );
      return;
    }
    const url = REACT_APP_API_DOMAIN + '/admin/caregiver/' + caregiver?.id;
    const headers = new Headers();
    headers.append('Authorization', accessToken);
    headers.append('Content-Type', 'application/json');
    const options = { method: 'DELETE', headers };
    const response = await fetch(url, options);
    queryClient.invalidateQueries();
    setCaregiver(undefined);
    navigate('/editcaregiver');
  };
  return (
    <>
      <div>Caregiver Information</div>
      <div>Org: {org?.name}</div>
      <div>Home: {home?.name}</div>
      <TextInput title="name" value={name} setValue={setName} />
      <TextInput title="email" value={email} setValue={setEmail} />
      {caregiver ? (
        <>
          <Checkbox
            title="new password?"
            hoverText="password must be at least 8 characters"
            value={newPassword}
            setValue={setNewPassword}
          />
          {newPassword ? <TextInput title="password" value={password} setValue={setPassword} /> : null}
        </>
      ) : (
        <>
          <Checkbox
            title="click here if the email address does not actually exist"
            hoverText="if set, account cannot be sent emails or use forgot password"
            value={autoVerification}
            setValue={setAutoVerification}
          />
          <Checkbox title="auto generate password?" value={generatePassword} setValue={setGeneratePassword} />
          {!generatePassword ? <TextInput title="password" value={password} setValue={setPassword} /> : null}
        </>
      )}

      <TextInput title="phone" value={phone} setValue={setPhone} />

      <TextInput title="city" value={city} setValue={setCity} />
      <TextInput title="state" value={state} setValue={setState} />
      <TextInput title="country" value={country} setValue={setCountry} />
      <TextInput title="profession" value={profession} setValue={setProfession} />
      {caregiver ? <TextInput title="homeId" value={homeId} setValue={setHomeId} /> : null}
      <div>Role</div>
      <div>
        <input
          type="radio"
          value="caregiver"
          checked={role === 'caregiver'}
          onChange={(e) => setRole(e.target.value)}
        />{' '}
        Caregiver
        <input
          type="radio"
          value="home-admin"
          checked={role === 'home-admin'}
          onChange={(e) => setRole(e.target.value)}
        />{' '}
        Home admin
      </div>
      <Checkbox title="is live" value={isLive} setValue={setIsLive} />
      <button onClick={() => handleSubmit()}>{caregiver ? 'save' : 'add'}</button>
      {caregiver ? <button onClick={() => handleDelete()}>delete</button> : null}
      <button
        onClick={() => {
          setCaregiver(undefined);
          navigate(-1);
        }}
      >
        Back
      </button>
    </>
  );
}

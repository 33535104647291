import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accessTokenAtom, orgAtom } from '../atoms';
import { REACT_APP_API_DOMAIN } from '../env';
import { Organisation } from '../types';

const orgs = [
  {
    id: 'string',
    name: 'org1',
    location: 'string',
    country: 'string',
    city: 'string',
    salesInfo: {
      name: 'string',
      email: 'string',
      phone: 'string',
    },
    isLive: true,
  },
  {
    id: 'string',
    name: 'org2',
    location: 'string',
    country: 'string',
    city: 'string',
    salesInfo: {
      name: 'string',
      email: 'string',
      phone: 'string',
    },
    isLive: true,
  },
];
export default function EditOrganisation() {
  const [org, setOrg] = useAtom(orgAtom);
  const [orgs, setOrgs] = useState<Organisation[]>([]);
  const [accessToken] = useAtom(accessTokenAtom);
  console.log({ accessToken });
  const navigate = useNavigate();
  useEffect(() => {
    if (!accessToken) {
      navigate('/');
    }
  }),
    [accessToken];
  const getOrgs = async () => {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);
    const resp = await fetch(REACT_APP_API_DOMAIN + '/admin/organisation', {
      headers,
    });
    return (await resp.json()) as Organisation[];
  };
  // useEffect(() => {
  //   getOrgs();
  // }, []);

  const { data, isLoading, isError } = useQuery({ queryKey: ['getOrganisations'], queryFn: getOrgs });
  if (org) {
    return (
      <>
        <div>{'Editing ' + org.name}</div>
        <button onClick={() => navigate('/orgform')}>Edit Org info</button>
        <button onClick={() => navigate('/homeform')}>add home</button>
        <button onClick={() => navigate('/edithome')}>edit home</button>
        <button onClick={() => setOrg(undefined)}>Back</button>
      </>
    );
  }
  if (isLoading) {
    return <div>loading</div>;
  }
  if (isError) {
    return <div>Something went wrong, please refresh</div>;
  }
  return (
    <>
      <div>Edit Organisation</div>
      {data.length ? (
        data.map((o, i) => (
          <button onClick={() => setOrg(o)} key={i}>
            {o.name}
          </button>
        ))
      ) : (
        <div>No orgs created</div>
      )}
      <button onClick={() => navigate('/')}>back</button>
    </>
  );
}

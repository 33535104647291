import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accessTokenAtom, homeAtom, orgAtom } from '../atoms';
import { REACT_APP_API_DOMAIN } from '../env';
import { Home } from '../types';

export default function EditHome() {
  const [org, setOrg] = useAtom(orgAtom);
  const [home, setHome] = useAtom(homeAtom);
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      navigate('/');
    }
  }),
    [accessToken];

  const getHomes = async () => {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);
    const resp = await fetch(REACT_APP_API_DOMAIN + '/admin/organisation/' + org?.id + '/homes', {
      headers,
    });
    return (await resp.json()) as Home[];
  };
  const { data, isLoading, isError } = useQuery({ queryKey: ['getHomes'], queryFn: getHomes });
  if (home) {
    return (
      <>
        <div>{'Editing ' + home.name}</div>
        <button onClick={() => navigate('/homeform')}>Edit home info</button>
        <button onClick={() => navigate('/caregiverform')}>add caregiver/admin</button>
        <button onClick={() => navigate('/editcaregiver')}>edit caregiver/admin</button>
        <button onClick={() => navigate('/deletelistener')}>edit listener</button>
        <button onClick={() => setHome(undefined)}>Back</button>
      </>
    );
  }

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (isError) {
    return <div>Something went wrong</div>;
  }
  return (
    <>
      <div>Edit Home</div>
      {data.length ? (
        data.map((o) => (
          <button onClick={() => setHome(o)} key={o.name}>
            {o.name}
          </button>
        ))
      ) : (
        <div>There are no homes</div>
      )}
      <button onClick={() => navigate('/editorg')}>back</button>
    </>
  );
}

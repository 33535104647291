import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accessTokenAtom, caregiverAtom, homeAtom, orgAtom } from '../atoms';
import { REACT_APP_API_DOMAIN } from '../env';
import { Caregiver } from '../types';

export default function EditCaregiver() {
  const [caregiver, setCaregiver] = useAtom(caregiverAtom);
  const navigate = useNavigate();
  const [home] = useAtom(homeAtom);
  const [accessToken] = useAtom(accessTokenAtom);
  useEffect(() => {
    if (!accessToken) {
      navigate('/');
    }
  }),
    [accessToken];
  const getCaregivers = async () => {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);
    const resp = await fetch(REACT_APP_API_DOMAIN + '/admin/caregivers?home_id=' + home?.id, {
      headers,
    });
    return (await resp.json()) as Caregiver[];
  };
  const { data, isLoading, isError } = useQuery({ queryKey: ['getCaregivers'], queryFn: getCaregivers });
  if (isLoading) {
    return <div>Loading</div>;
  }
  if (isError) {
    return <div>Something went wrong. Please refresh</div>;
  }
  return (
    <>
      <div>Edit Caregiver</div>
      {data.length ? (
        data.map((c) => (
          <button
            onClick={() => {
              setCaregiver(c);
              navigate('/caregiverform');
            }}
            key={c.email}
          >
            {c.email}
          </button>
        ))
      ) : (
        <div>No caregivers</div>
      )}
      <button onClick={() => navigate('/edithome')}>back</button>
    </>
  );
}

const CheckBox = ({
  title,
  value,
  hoverText,
  setValue,
}: {
  title: string;
  value: boolean;
  hoverText?: string;
  setValue: (b: boolean) => void;
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <label>{title}</label>
      <input
        type="checkbox"
        title={hoverText}
        onChange={(e) => {
          setValue(e.target.checked);
        }}
        checked={value}
      />
    </div>
  );
};

export default CheckBox;

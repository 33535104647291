import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import OrgForm from './pages/OrgForm';
import EditOrganisation from './pages/EditOrganisation';
import HomeForm from './pages/HomeForm';
import OrgInformation from './pages/OrgForm';
import EditHome from './pages/EditHome';
import CaregiverForm from './pages/CaregiverForm';
import EditCaregiver from './pages/EditCaregiver';
import DeleteVeraUser from './pages/DeleteVeraUser';
import DeleteListener from './pages/DeleteListener';
import { accessTokenAtom } from './atoms';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/orgform" element={<OrgForm />}></Route>
        <Route path="/editorg" element={<EditOrganisation />}></Route>
        <Route path="/homeform" element={<HomeForm />}></Route>
        <Route path="/edithome" element={<EditHome />}></Route>
        <Route path="/caregiverform" element={<CaregiverForm />}></Route>
        <Route path="/editcaregiver" element={<EditCaregiver />}></Route>
        <Route path="/deleteverauser" element={<DeleteVeraUser />}></Route>
        <Route path="/deletelistener" element={<DeleteListener />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
